import { Routes } from '@angular/router';
import { WebsiteComponent } from './modules/website/website.component';

export const routes: Routes = [
  {
    path: '',
    component: WebsiteComponent,
    loadChildren: () =>
      import('./modules/website/website.routes').then((m) => m.routes),
  },
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];
