import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-website',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './website.component.html',
  styleUrl: './website.component.scss',
})
export class WebsiteComponent implements OnInit, OnDestroy {
  constructor() {
    console.log('WebsiteComponent constructor');
  }

  ngOnInit(): void {
    console.log('WebsiteComponent ngOnInit');
  }

  ngOnDestroy(): void {
    console.log('WebsiteComponent ngOnDestroy');
  }
}
